import React, { useContext } from "react"

import Dropdown from "react-bootstrap/Dropdown"

import Image from "../../custom/Image"
import OverlayTooltip from "../../custom/OverlayTooltip"
import InsightModal from "../InsightModal"

import InsightStore, { InsightContext } from "../../../stores/insight_store/InsightStore"
import { SET_INSIGHT_DATA, SET_ACTIVE_MODULE_DATA } from "../../../stores/insight_store/InsightActions"
import { ClassroomContext } from "../../../stores/classroom_store/ClassroomStore"

import InsightIconStyle from "./style.module.scss"

import InsightIcon from "../../../images/svgs/insights/insights.svg"
import SessionBurnDownIcon from "../../../images/svgs/insights/session_burndown_insights.svg"

const LessonBoardInsight = ({ session, template_id, training_id, type }) => {
  const [state, dispatch] = useContext(InsightContext)
  //Have to use this state data to get latest graphs.
  const [classroomState, classroomStateDispatch] = useContext(ClassroomContext)

  return (
    <div className="flex-shrink-0 fs-16px">
      <Dropdown className="breakout-groups move-dropdown">
        <Dropdown.Toggle
          className={`border-none px-0 pt-0 ${
            type === "dashboard" ? `mb-2 ${InsightIconStyle.insight_icon_container_dashbaord}` : InsightIconStyle.insight_icon_container
          } insight-icon-hover`}
          id="classroom-insight-icon"
        >
          <OverlayTooltip
            tooltipText={type === "dashboard" ? "View Insights" : "View Session Insights"}
            text={
              <Image
                src={InsightIcon}
                alt={`insights`}
                className={`${type === "dashboard" ? InsightIconStyle.insight_icon_dashboard : InsightIconStyle.insight_icon}`}
              />
            }
            tooltipStyle={{
              fontSize: 10,
              backgroundColor: "#061124",
              color: "white",
              marginTop: "15px",
            }}
            placement={"bottom"}
            insightsOverlay={true}
          />
        </Dropdown.Toggle>
        <Dropdown.Menu className=" mt-2">
          <Dropdown.Item
            onClick={(e) => {
              e.preventDefault()
              dispatch({ type: SET_INSIGHT_DATA, payload: { data: {}, type: "session_burndown", activeChart: "Session Burndown Chart" } })
              if (session?.session_type !== "0") {
                dispatch({ type: SET_ACTIVE_MODULE_DATA, payload: { ...session } })
              }
            }}
            className="text-white fs-14px px-2 align-items-center d-flex"
          >
            <Image src={SessionBurnDownIcon} className={`${InsightIconStyle.insight_list_icon}`} alt={`Session Burndown`} />
            <span className="ml-1">Session Burndown</span>
          </Dropdown.Item>
          <Dropdown.Item
            onClick={(e) => {
              e.preventDefault()
              dispatch({ type: SET_INSIGHT_DATA, payload: { data: {}, type: "class_burndown", activeChart: "Class Burndown" } })
            }}
            className="text-white fs-14px px-2 align-items-center d-flex"
          >
            <Image src={SessionBurnDownIcon} className={`${InsightIconStyle.insight_list_icon}`} alt={`Class Burndown`} />
            <span className="ml-1">Class Burndown</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      {(state?.type === "class_burndown" || state?.type === "session_burndown") && (
        <InsightModal
          show={true}
          template_id={template_id ? template_id : classroomState?.template_id}
          training_id={training_id ? training_id : classroomState?.training_id}
          module_id={session?.id}
        />
      )}
    </div>
  )
}

const LessonBoardInsightWrapper = ({ session, training_id, template_id, type }) => {
  return (
    <InsightStore>
      <LessonBoardInsight session={session} training_id={training_id} template_id={template_id} type={type} />
    </InsightStore>
  )
}

export default LessonBoardInsightWrapper
