import React, { useState, useRef, useEffect } from "react"
import Overlay from "react-bootstrap/Overlay"
import Style from "./style.module.scss"

export default function OverlayTooltip(props) {
  const [show, _setShow] = useState(false)
  const showRef = React.useRef(show)
  const setShow = (data) => {
    showRef.current = data
    _setShow(data)
  }
  const target = useRef(null)
  const callShowHide = () => {
    _setShow((prev) => {
      let val = !prev
      showRef.current = val
      return val
    })
  }
  const callShow = () => {
    setShow(true)
  }
  const callHide = () => {
    setShow(false)
  }

  const callShowEvent = (e) => {
    showRef.current && setShow(false)
  }

  useEffect(() => {
    if (props?.trigger === "click") {
      document.addEventListener("click", callShowEvent, true)
      return () => {
        document.removeEventListener("click", callShowEvent, true)
      }
    }
  }, [props?.trigger])

  const Render = () => {
    let tooltipClass = props.tooltipClass,
      tooltipStyle = props.tooltipStyle,
      tooltipText = props.tooltipText,
      format = props.format ?? "text"
    let myProps = {
      ref: target,
      className: `${props.textClass} d-flex align-items-center m-0 pointer ${Style.tooltip_text}`,
      style: { ...props.textStyle },
    }
    if (props?.trigger === undefined || props?.trigger !== "click") {
      myProps.onMouseEnter = callShow
      myProps.onMouseLeave = callHide
    } else {
      myProps.onClick = callShowHide
    }
    let trigger = props?.trigger

    if (props?.insightsOverlay == true) {
      myProps.className = props.textClass
      myProps.style = { ...props.textStyle, textOverflow: "ellipsis", cursor: "pointer" }
    }
    return (
      <>
        {format === "text" ? <span {...myProps}>{props?.text}</span> : <span {...myProps} dangerouslySetInnerHTML={{ __html: props?.text }} />}
        <Overlay target={target.current} show={show} placement={props?.placement ?? "bottom-start"}>
          {({ placement, arrowProps, show: _show, popper, ...props }) => (
            <>
              <div
                {...props}
                className={tooltipClass}
                style={{
                  padding: "0.75rem",
                  textAlign: "justify",
                  backgroundColor: "#1A1A1A",
                  borderRadius: "0.3rem",
                  maxWidth: `${tooltipText.length > 30 ? "50vw" : "300px"}`,
                  width: `fit-content`,
                  zIndex: 999999,
                  ...props.style,
                  ...tooltipStyle,
                }}
              >
                {trigger === "click" && (
                  <div className="d-flex justify-content-end" style={{ padding: 1 }}>
                    <img
                      onClick={() => setShow(false)}
                      src={"/cancel-white-icon.svg"}
                      alt={`Cancel`}
                      className={`mb-0 pointer`}
                      style={{ objectFit: `contain`, height: 15, width: 15 }}
                    />
                  </div>
                )}
                <div dangerouslySetInnerHTML={{ __html: tooltipText }} />
              </div>
            </>
          )}
        </Overlay>
      </>
    )
  }
  return Render()
}
