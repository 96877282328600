import React from "react"
import { Router } from "@reach/router"
import { isBrowser } from "../services/developer_mode"

import Dashboard from "../components/dashboard"
import Login from "../components/login"
import PrivateRoute from "../components/PrivateRoute"

const IndexPage = (props) => (
  <Router basepath="/">
    {isBrowser() ? <PrivateRoute path="/" component={Dashboard} props={props} /> : null}
    {/* <Login path="/" /> */}
    <Login path="/login" />
  </Router>
)

export default IndexPage
